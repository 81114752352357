import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import classNames from "classnames"
import { EventLandingPageQuery } from "../../../graphql/types"
import { defaultMetadata, PathPrefix } from "../../core/constants"
import styles from "./event.module.scss"
import { PageProps } from "@src-types/page"
import { Page } from "@components/page/page"
import { CallToAction } from "@components/call-to-action/call-to-action"
import { TeachingFellowship } from "@features/about/components/teaching-fellowship/teaching-fellowship"
import AdditionalInfo from "@components/AdditionalInfo/additionalInfo"

export const EventLandingPage: FunctionComponent<
  PageProps<EventLandingPageQuery>
> = (props) => {
  const {
    heroTitle,
    heroType,
    heroSubtitle,
    heroExtraLine,
    heroButtonText,
    seo,
    heroPosterVideo,
    headerImage,
    headerOverline,
    headerTitle,
    headerBody,
    headerButton1Url,
    headerButton1Text,
    headerButton2Url,
    headerButton2Text,
    speakersTitle,
    speakers,
    speakersButtonUrl,
    speakersButtonText,
    registrationImage,
    registrationOverline,
    registrationTitle,
    registrationBody,
    registrationButtonUrl,
    registrationButtonText,
    additionalInfoOverline,
    additionalInfoTitle,
    additionalInfoBody,
    additionalInfoButtonUrl,
    additionalInfoButtonText,
    heroImage,
    headerImageBackgroundColor,
    registrationImageBackgroundColor,
    heroVideo
  } = props?.data?.contentfulEventLandingPage || {}

  const selectedHeroType = heroType?.includes("Video") ? "Video" : "Image"

  const metadataOverrides = {
    title: seo?.title || defaultMetadata.title,
    description: seo?.description || defaultMetadata.description,
    openGraphImage: seo?.image?.fixed?.src
  }

  const headerVideoOptions = {
    posterVideoId: heroPosterVideo?.brightcoveId || "",
    videoId: heroVideo?.brightcoveId || "",
    heading: heroTitle?.childMarkdownRemark?.rawMarkdownBody || "",
    quote: heroSubtitle || "",
    attribution: heroExtraLine || "",
    buttonText: heroButtonText || ""
  }

  const speakersList = speakers?.map((speaker) => ({
    name: speaker?.shortName || "",
    image: speaker?.image?.fluid?.src || "",
    url: `${PathPrefix.Teachers}/${speaker?.slug}`
  }))

  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        ...metadataOverrides
      }}
      isInfoIconShown={true}
      pageOptions={{
        headerVariant: selectedHeroType,
        headerVideoOptions,
        heroImageSrc: heroImage?.file?.url || ""
      }}
    >
      <div className={classNames(styles.section, styles.header)}>
        <CallToAction
          title={headerTitle || ""}
          overline={headerOverline || ""}
          body={headerBody?.childMarkdownRemark?.rawMarkdownBody || ""}
          buttonText={headerButton1Text || ""}
          buttonUrl={headerButton1Url || ""}
          buttonTwoText={headerButton2Text}
          buttonTwoUrl={headerButton2Url}
          imageFluid={headerImage?.fluid as FluidObject}
          boxShadowColor={headerImageBackgroundColor}
          variant="primary"
        />
      </div>
      <div className={classNames(styles.section, styles.speakers)}>
        <TeachingFellowship
          title={speakersTitle || ""}
          teachers={speakersList || []}
          button={{
            text: speakersButtonText || "",
            url: speakersButtonUrl || ""
          }}
        />
      </div>
      <div className={classNames(styles.section, styles.registration)}>
        <CallToAction
          title={registrationTitle || ""}
          overline={registrationOverline || ""}
          subtitle={
            registrationBody?.childMarkdownRemark?.rawMarkdownBody || ""
          }
          buttonText={registrationButtonText || ""}
          buttonUrl={registrationButtonUrl || ""}
          imageFluid={registrationImage?.fluid as FluidObject}
          boxShadowColor={registrationImageBackgroundColor}
          variant="secondary"
        />
      </div>
      <div>
        <AdditionalInfo
          overline={additionalInfoOverline || ""}
          title={additionalInfoTitle || ""}
          body={additionalInfoBody?.childMarkdownRemark?.rawMarkdownBody || ""}
          buttonText={additionalInfoButtonText || ""}
          buttonUrl={additionalInfoButtonUrl || ""}
        />
      </div>
    </Page>
  )
}

export default EventLandingPage

export const query = graphql`
  query EventLandingPage($slug: String) {
    contentfulEventLandingPage(slug: { eq: $slug }) {
      heroPosterVideo {
        brightcoveId
      }
      heroVideo {
        brightcoveId
      }
      seo {
        ...Metadata
      }
      heroTitle {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      heroSubtitle
      heroExtraLine
      heroButtonText
      headerImage {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      headerOverline
      headerTitle
      headerBody {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      headerButton1Url
      headerButton1Text
      headerButton2Url
      headerButton2Text
      speakersTitle
      speakers {
        image {
          fluid(maxWidth: 100) {
            src
          }
        }
        shortName
        slug
      }
      speakersButtonUrl
      speakersButtonText
      registrationImage {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      registrationOverline
      registrationTitle
      registrationBody {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      registrationButtonUrl
      registrationButtonText
      additionalInfoOverline
      additionalInfoTitle
      additionalInfoBody {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      additionalInfoButtonUrl
      additionalInfoButtonText
      headerImageBackgroundColor
      registrationImageBackgroundColor
      heroType
      heroImage {
        file {
          url
        }
      }
    }
  }
`
